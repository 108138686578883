// 静态路由
export const staticroute = [
    {
        path: '/',
        name: 'login',
        hidden: true,
        component: () => import('../views/login/login.vue'),
        meta: {
            title: '零度办公'
        }
    },
    //前台路由
    {
        path: '/modules',
        name: 'modules',
        component: () => import('../views/modules'),
        meta: {},
        children: [
            {
                path: '/application',
                name: 'application',
                component: () => import('../views/modules/application'),
                meta: {
                    // requiresAuth: true,
                },
                children: [

                    {
                        path: '/enterprise-file-lib',
                        name: 'enterprise-file-lib',
                        component: () => import('../views/modules/application/enterprise-file-lib'),
                        meta: {
                            title: '企业文件库',
                            requireAuth: true,
                            icon: 'iconfont icon-quyudangan',
                        },
                    },
                    {
                        path: '/konwledge-base',
                        name: 'konwledge-base',
                        component: () => import('../views/modules/application/knowledge-base'),
                        meta: {
                            title: '企业知识库',
                            requireAuth: true,
                            icon: 'iconfont icon-quyudangan',
                        },
                    },
                    {
                        path: '/template-management',
                        name: 'template-management',
                        component: () => import('../views/modules/application/template-management'),
                        meta: {
                            title: '模板管理',
                            requireAuth: true,
                            icon: 'iconfont icon-mobanguanli',
                        },
                    },
                    {
                        path: '/my-team',
                        name: 'my-team',
                        component: () => import('../views/modules/application/my-team'),
                        meta: {
                            title: '我的团队',
                            requireAuth: true,
                            icon: 'iconfont icon-tuanduiguanli',
                        },
                    },
                    {
                        path: '/recycling-station',
                        name: 'recycling-station',
                        component: () => import('../views/modules/application/recycling-station'),
                        meta: {
                            title: '回收站',
                            requireAuth: true,
                            icon: 'iconfont icon-huishouzhan',
                        },
                    },
                ],
            },
            {
                path: '/document',
                name: 'document',
                component: () => import('../components/fileEditor/office/onlyoffice2.vue'),
                meta: {
                    title: '文件编辑',
                    requireAuth: true,
                },
            },
            {
                path: '/preview',
                name: 'preview',
                component: () => import('../components/preview/preview.vue'),
                meta: {
                    title: '文件预览',
                    requireAuth: true,
                },
            },
            {
                path: '/ueditor-plus',
                name: 'ueditor-plus',
                component: () => import('../components/fileEditor/text/ueditor-plus-tag.vue'),
                meta: {
                    title: '文件编辑',
                    requireAuth: true,
                },
            },
            {
                path: '/share-view',
                name: 'share-view',
                component: () => import('../components/fileEditor/office/onlyoffice_share.vue'),
            },
            //企业后台管理
            {
                path: '/system',
                name: 'system',
                component: () => import('../views/modules/system'),
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '/enterprise-management',
                        name: 'enterprise-management',
                        component: () => import('../views/modules/system/enterprise-management'),
                        meta: {
                            title: '企业管理',
                            requireAuth: true,
                            icon: 'iconfont icon-qiyeguanli',
                        },
                        children: [
                            {
                                path: '/system/enterprise-user',
                                name: 'enterprise-user',
                                component: () => import('../views/modules/system/enterprise-management/enterprise-user'),
                                meta: {
                                    title: '企业用户',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/system/examine-user',
                                name: 'examine-user',
                                component: () => import('../views/modules/system/enterprise-management/enterprise-user/examine-user'),
                                meta: {
                                    title: '待审核用户',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/system/organization',
                                name: 'organization',
                                component: () => import('../views/modules/system/enterprise-management/organization'),
                                meta: {
                                    title: '组织机构',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/system/role',
                                name: 'system-role',
                                component: () => import('../views/modules/system/enterprise-management/role'),
                                meta: {
                                    title: '角色管理',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/system/gangwei',
                                name: '/system/gangwei',
                                component: () => import('../views/modules/system/enterprise-management/sys_gangwei'),
                                meta: {
                                    title: '岗位管理',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/system/group-management',
                                name: 'group-management',
                                component: () => import('../views/modules/system/enterprise-management/group-management'),
                                meta: {
                                    title: '企业用户组管理',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/system/group-sys-management',
                                name: 'group-sys-management',
                                component: () => import('../views/modules/system/enterprise-management/group-sys-management'),
                                meta: {
                                    title: '系统用户组管理',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/system/info-maintain',
                                name: 'info-maintain',
                                component: () => import('../views/modules/system/enterprise-management/info-maintain'),
                                meta: {
                                    title: '企业信息维护',
                                    requireAuth: true,
                                    // icon: 'el-icon-notebook-1',
                                },
                            },
                        ]
                    },
                    {
                        path: '/system/dict',
                        name: 'system-dict',
                        component: () => import('../views/modules/system/dictionary'),
                        meta: {
                            title: '字典设置',
                            requireAuth: true,
                            icon: 'iconfont icon-shujuzidian',
                        },
                    },
                    /*{
                        path: '/system/index',
                        name: 'system-index',
                        component: () => import('../views/modules/system/sys90_index_description'),
                        meta: {
                            title: '名词解释',
                            requireAuth: true,
                            icon: 'iconfont icon-shujuzidian',
                        },
                    },*/
                    {
                        path: '/system/sensitive-word',
                        name: 'system-sensitive-word',
                        component: () => import('../views/modules/system/sensitive-word'),
                        meta: {
                            title: '敏感词设置',
                            requireAuth: true,
                            icon: 'iconfont icon-gerenzhongxin-anquanshezhi',
                        },
                    },
                    {
                        path: '/system/classification-management',
                        name: 'classify-management',
                        component: () => import('../views/modules/system/classification-management'),
                        meta: {
                            title: '分类管理',
                            requireAuth: true,
                            icon: 'iconfont icon-icon-test',
                        }
                    },
                    {
                        path: '/system/log-audit',
                        name: 'log-audit',
                        component: () => import('../views/modules/system/log-audit'),
                        meta: {
                            title: '日志审计',
                            requireAuth: true,
                            icon: 'iconfont icon-rizhiguanli',
                        },
                        children: [
                            {
                                path: '/system/login-log',
                                name: 'login-log',
                                component: () => import('../views/modules/system/log-audit/login-log'),
                                meta: {
                                    title: '登录日志',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/system/operation-log',
                                name: 'operation-log',
                                component: () => import('../views/modules/system/log-audit/operation-log'),
                                meta: {
                                    title: '操作日志',
                                    requireAuth: true,
                                },
                            },
                        ]
                    },
                ]
            },
            // 文章管理
            {
                path: '/information',
                name: 'information',
                component: () => import('../views/modules/information'),
                meta: {
                    //requiresAuth: true,
                },

                children: [{
                    path: '/information-article-list',
                    name: 'information-article-list',
                    component: () => import('../views/modules/information/cms/cms_article/article-list'),
                    meta: {
                        title: '文章管理',
                        requireAuth: true,
                        icon: 'iconfont icon-wenzhangguanli',
                    },
                },
                {
                    path: '/information-column-list',
                    name: 'information-column-list',
                    component: () => import('../views/modules/information/cms/cms_catalog/column-list'),
                    meta: {
                        title: '栏目管理',
                        requireAuth: true,
                        icon: 'iconfont icon-dangjian_lanmuguanli',
                    },
                }, {
                    path: '/information-template-list-preview',
                    name: 'information-template-list-preview',
                    component: () => import('../views/modules/information/cms/cms_template_preview/template-list-preview.vue'),
                    meta: {
                        title: '模板预览',
                        requireAuth: true,
                        icon: 'iconfont icon-preview',
                    },
                }, {
                    path: '/information-process-setup',
                    name: 'information-process-setup',
                    component: () => import('../views/modules/information/cms/cms_workflow_setting'),
                    meta: {
                        title: '发布设置',
                        requireAuth: true,
                        icon: 'iconfont icon-fabu',
                    },
                }, {
                    path: '/information-zipimg-list',
                    name: 'information-zipimg-list',
                    component: () => import('../views/modules/information/cms/cms_article_zip_img/zipimg-list'),
                    meta: {
                        title: '压缩包图标',
                        requireAuth: true,
                        icon: 'iconfont icon-wenjian_yasuobao',
                    },
                }, {
                    path: '/information-log-list',
                    name: 'information-log-list',
                    component: () => import('../views/modules/information/cms/cms_access_log/log-list.vue'),
                    meta: {
                        title: '访问日志',
                        requireAuth: true,
                        icon: 'iconfont icon-rizhiguanli',
                    },
                },
                ]
            },


            // 系统后台路由
            {
                path: '/backstage',
                name: 'backstage',
                component: () => import('../views/modules/backstage'),
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '/backstage/classification-management',
                        name: 'classification-management',
                        component: () => import('../views/modules/backstage/classification-management'),
                        meta: {
                            title: '分类管理',
                            requireAuth: true,
                            icon: 'iconfont icon-icon-test',
                        }
                    },
                    {
                        path: '/backstage/menu',
                        name: 'menu',
                        component: () => import('../views/modules/backstage/menu'),
                        meta: {
                            title: '资源管理',
                            requireAuth: true,
                            icon: 'iconfont icon-a-23caidanguanli'
                        },
                    },
                    {
                        path: '/message-management',
                        name: 'message-management',
                        component: () => import('../views/modules/backstage/message-management'),
                        meta: {
                            title: '消息平台',
                            requireAuth: true,
                            icon: 'iconfont icon-xiaoxi',
                        },
                        children: [
                            {
                                path: '/message-platform',
                                name: 'message-platform',
                                component: () => import('../views/modules/backstage/message-management/message-platform'),
                                meta: {
                                    title: '消息记录',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/message-fail',
                                name: 'message-fail',
                                component: () => import('../views/modules/backstage/message-management/message-fail'),
                                meta: {
                                    title: '失败记录',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/configuration',
                                name: 'configuration',
                                component: () => import('../views/modules/backstage/message-management/configuration'),
                                meta: {
                                    title: '配置管理',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/message-send',
                                name: 'message-send',
                                component: () => import('../views/modules/backstage/message-management/message-platform/message-send'),
                                meta: {
                                    title: '消息发送测试',
                                    requireAuth: true,
                                },
                            },
                        ]
                    },
                    {
                        path: '/backstage/dictionary',
                        name: 'dictionary',
                        component: () => import('../views/modules/backstage/dictionary'),
                        meta: {
                            title: '字典管理',
                            requireAuth: true,
                            icon: 'iconfont icon-shujuzidian',
                        }
                    },
                    {
                        path: '/backstage/dictEnterprise',
                        name: 'dict-enterprise',
                        component: () => import('../views/modules/backstage/dictEnterprise'),
                        meta: {
                            title: '企业字典设置',
                            requireAuth: true,
                            icon: 'iconfont icon-shujuzidian',
                        }
                    },
                    {
                        path: '/backstage/sysGroup',
                        name: 'sys-group',
                        component: () => import('../views/modules/backstage/group-system'),
                        meta: {
                            title: '系统用户组设置',
                            requireAuth: true,
                            icon: 'iconfont icon-tuanduiguanli',
                        }
                    },
                    {
                        path: '/scheduled-task',
                        name: 'scheduled-task',
                        component: () => import('../views/modules/backstage/scheduled-task'),
                        meta: {
                            title: '定时任务',
                            requireAuth: true,
                            icon: 'iconfont icon-dingshirenwuguanli',
                        },
                    },
                    {
                        path: '/gen',
                        name: 'gen',
                        component: () => import('../views/modules/backstage/gen'),
                        meta: {
                            title: '代码生成',
                            requireAuth: true,
                            icon: 'iconfont icon-daimashengcheng',
                        },
                    },

                    {
                        path: '/common-examples',
                        name: 'common-examples',
                        component: () => import('../views/modules/backstage/common-examples'),
                        meta: {
                            title: '常用示例',
                            requireAuth: true,
                            icon: 'iconfont icon-jihuaguanli',
                        },
                        children: [
                            {
                                path: '/query-list',
                                name: 'query-list',
                                component: () => import('../views/modules/backstage/common-examples/query-list'),
                                meta: {
                                    title: '查询列表',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/common-form',
                                name: 'common-form',
                                component: () => import('../views/modules/backstage/common-examples/common-form'),
                                meta: {
                                    title: '常用表单',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/mechanism-tree',
                                name: 'mechanism-tree',
                                component: () => import('../views/modules/backstage/common-examples/mechanism-tree'),
                                meta: {
                                    title: '常用树',
                                    requireAuth: true,
                                },
                            },
                            {
                                path: '/classification-example-parent',
                                name: 'classification-example-parent',
                                component: () => import('../views/modules/backstage/classifyExample/classify-example-parent.vue'),
                                meta: {
                                    title: '分类使用',
                                    requireAuth: true,
                                    // icon: 'el-icon-position',
                                },
                            }
                        ]
                    },
                    {
                        path: '/sys_module_auth_def',
                        name: 'sys_module_auth_def',
                        component: () => import('../views/modules/backstage/sys_module_auth_def'),
                        meta: {
                            title: '通用权限管理',
                            requireAuth: true,
                            icon: 'iconfont icon-quanxianweihu',
                        },
                    },
                    {
                        path: '/backstage/fileManage',
                        name: 'fileManage',
                        component: () => import('../views/modules/backstage/fileManage'),
                        meta: {
                            title: '文件管理后台',
                            requireAuth: true,
                            icon: 'iconfont icon-16',
                        }
                    },
                    {
                        path: '/template-list',
                        name: 'template-list',
                        component: () => import('../views/modules/backstage/cms_template/template-list'),
                        meta: {
                            title: '文章模板',
                            requireAuth: true,
                            icon: 'iconfont icon-mobanguanli',
                        },
                    },
                    {
                        path: '/fd-web',
                        name: 'e-form',
                        // component: () => import('../views/modules/backstage/cms_template/template-list'),
                        meta: {
                            title: '电子表单',
                            requireAuth: true,
                            icon: 'iconfont icon-biaodanzujian-biaoge',
                        },
                    },
                    {
                        path: '/backstage/task-monitoring',
                        name: 'task-monitoring',
                        component: () => import('../views/modules/backstage/task-monitoring'),
                        meta: {
                            title: '任务监听',
                            requireAuth: true,
                            icon: 'iconfont icon-monitor',
                        },
                    },
                    {
                        path: '/backstage/plan-management',
                        name: 'plan-management',
                        component: () => import('../views/modules/backstage/plan-management'),
                        meta: {
                            title: '计划管理',
                            requireAuth: true,
                            icon: 'iconfont icon-jihuaguanli',
                        },
                    },
                ]
            },
            // 工作提醒
            {
                path: '/work-reminder',
                name: 'work-reminder',
                component: () => import('../views/modules/work-reminder'),
                meta: {
                    title: '工作提醒',
                    requireAuth: true,
                    icon: 'iconfont icon-renwubanli',
                },
                children: [
                    {
                        path: '/todo-list',
                        name: 'todo-list',
                        component: () => import('../views/modules/work-reminder/todo-list'),
                        meta: {
                            title: '待办工作',
                            requireAuth: true,
                            icon: '',
                        },
                    },
                    {
                        path: '/completed-work',
                        name: 'completed-work',
                        component: () => import('../views/modules/work-reminder/completed-work'),
                        meta: {
                            title: '已办工作',
                            requireAuth: true,
                            icon: '',
                        },
                    },
                    {
                        path: '/Jobtracking',
                        name: 'Jobtracking',
                        component: () => import('../views/modules/work-reminder/Jobtracking'),
                        meta: {
                            title: '部门工作跟踪',
                            requireAuth: true,
                            icon: '',
                        },
                    },
                    {
                        path: '/create-task',
                        name: 'create-task',
                        component: () => import('../views/modules/work-reminder/create-task'),
                        meta: {
                            title: '我创建的任务',
                            requireAuth: true,
                            icon: '',
                        },
                    },

                    {
                        path: '/periodic-task',
                        name: 'periodic-task',
                        component: () => import('../views/modules/work-reminder/periodic-task'),
                        meta: {
                            title: '周期任务',
                            requireAuth: true,
                            icon: '',
                        },
                    },

                ]
            },
            {
                path: '/create-personal-task',
                name: 'create-personal-task',
                component: () => import('../views/modules/work-reminder/create-task/addPersonal.vue'),
                meta: {
                    title: '新建个人任务',
                    requireAuth: true,
                    icon: '',
                },
            },
        ]
    },
    {
        path: '/comment',
        name: 'comment',
        hidden: true,
        component: () => import('../views/modules/application/comment'),
        meta: {
            title: '评论',
            requireAuth: true,
        },
    },
    {
        path: '/ordinary-list',
        name: 'ordinary-list',
        hidden: true,
        component: () => import('../views/modules/backstage/message-management/message-platform/ordinary-list'),
        meta: {
            title: '消息记录',
            requireAuth: true,
        },
    },
    {
        path: '/ordinary-view',
        name: 'ordinary-view',
        hidden: true,
        component: () => import('../views/modules/backstage/message-management/message-platform/ordinary-view'),
        meta: {
            title: '消息详情',
            requireAuth: true,
        },
    },
    {
        path: '/home-work-remind-todo',
        name: 'home-work-remind-todo',
        hidden: true,
        component: () => import('../views/modules/work-reminder/todo-list/home-task-handling'),
        meta: {
            title: '任务提醒待办',
            requireAuth: true,
        },
    },
    {
        path: '/personal-space',
        name: 'personal-space',
        hidden: true,
        component: () => import('../views/modules/application/personal-space'),
        meta: {
            title: '个人空间',
            requireAuth: true,
        },
    },
    {
        path: '/collect-manage',
        name: 'collect-manage',
        hidden: true,
        component: () => import('../views/modules/application/personal-collect-manage'),
        meta: {
            title: '收藏夹',
            requireAuth: true,
            // icon: 'iconfont icon-huishouzhan',
        },
    },
    {
        path: '/download',
        name: 'download',
        hidden: true,
        component: () => import('../views/modules/application/download-center/index'),
        meta: {
            title: '下载中心',
            requireAuth: true,

        },
    },
    {
        path: '/share/wechat',
        name: 'wechatShare',
        component: () => import('@/components/weixin/wechatShare.vue'),
        meta: {
            title: '分享',
            requireAuth: true,
        },
    },
    {
        path: '/invalid',
        name: 'invalid',
        component: () => import('@components/invalid.vue'),
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/help',
        name: 'help',
        component: () => import('../views/modules/backstage/cms_template/help.vue'),
        meta: {
            title: '模板制作说明',
            requireAuth: true,
        },
    },
    {
        path: '/default-pc-preview',
        name: 'default-pc-preview',
        component: () => import('../views/modules/information/cms/cms_template_preview/default/pc/preview.vue'),
        meta: {
            title: '默认pc预览',
            requireAuth: true,
        },
    },
    {
        path: '/default-mobile-preview',
        name: 'default-mobile-preview',
        component: () => import('../views/modules/information/cms/cms_template_preview/default/mobile/preview.vue'),
        meta: {
            title: '默认移动端预览',
            requireAuth: true,
        },
    },
    {
        path: '/message-pc-preview',
        name: 'message-pc-preview',
        component: () => import('../views/modules/information/cms/cms_template_preview/message/pc/preview.vue'),
        meta: {
            title: '消息pc预览',
            requireAuth: true,
        },
    },
    {
        path: '/message-mobile-preview',
        name: '/message-mobile-preview',
        component: () => import('../views/modules/information/cms/cms_template_preview/message/mobile/preview.vue'),
        meta: {
            title: '消息移动预览',
            requireAuth: true,
        },
    },
    {
        path: '/initialStage',
        name: 'initialStage',
        component: () => import('../views/modules/application/initialStage/index.vue'),
        meta: {
            title: '初始配置',
            requireAuth: true,
        },
    },
    {
        path: '/information-add-article',
        name: 'information-add-article',
        //hidden:true,
        component: () => import('../views/modules/information/cms/cms_article/add-article.vue'),
        meta: {
            title: '文章添加',
            requireAuth: false,
            // icon: 'iconfont icon-renwufabu',
        },
    },

    {
        path: '/information-zip-view',
        name: 'information-zip-view',
        //hidden:true,
        component: () => import('../views/modules/information/cms/cms_article/cms_zip.vue'),
        meta: {
            title: '压缩包查看',
            requireAuth: false,
            // icon: 'iconfont icon-renwufabu',
        }
    },
    {
        path: '/base/office',
        name: 'office',
        component: () => import('../components/fileEditor/base/onlyOfficePage.vue'),
        meta: {
            title: '编辑器',
            requireAuth: true,
        },
    },
    {
        path: '/base/text',
        name: 'text',
        component: () => import('../components/fileEditor/base/textEditorPage.vue'),
        meta: {
            title: '编辑器',
            requireAuth: true,
        },
    },
    {
        path: '/base/attachment',
        name: 'attachment',
        component: () => import('../components/fileEditor/base/fileAttachmentPage.vue'),
        meta: {
            title: '文件附件',
            requireAuth: true,
        },
    },
    {
        path: '/base/dataitem',
        name: 'dataitem',
        component: () => import('../components/fileEditor/base/fileDataitemPage.vue'),
        meta: {
            title: '文件数据项',
            requireAuth: true,
        },
    },
    {
        path: '/base/comment',
        name: 'comment',
        component: () => import('../components/fileEditor/base/commentPage.vue'),
        meta: {
            title: '评论',
            requireAuth: true,
        },
    },
    {
        path: '/base/history',
        name: 'history',
        component: () => import('../components/fileEditor/base/fileHistoryPage.vue'),
        meta: {
            title: '历史文件',
            requireAuth: true,
        },
    },
    {
        path: '/base/collect',
        name: 'collect',
        component: () => import('../components/fileEditor/base/collectPage.vue'),
        meta: {
            title: '文件收藏',
            requireAuth: true,
        },
    },
    {
        path: '/base/operationLog',
        name: 'operationLog',
        component: () => import('../components/fileEditor/base/fileOperateLogPage.vue'),
        meta: {
            title: '文件操作日志',
            requireAuth: true,
        },
    },
    {
        path: '/base/accessRecord',
        name: 'accessRecord',
        component: () => import('../components/fileEditor/base/accessRecordPage.vue'),
        meta: {
            title: '文件访问记录',
            requireAuth: true,
        },
    },
    {
        path: '/base/templateInfo',
        name: 'templateInfo',
        component: () => import('../components/fileEditor/base/templateInfoPage.vue'),
        meta: {
            title: '文件模板',
            requireAuth: true,
        },
    },


];