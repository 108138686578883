import sm4 from "@utils/sm4.js"
//import {SM4Util} from '@/utils/sm4_en';
import Base64 from "@/utils/base64";
import fa from "element-ui/src/locale/lang/fa";

// var sm4switch = this.global.expirationTime;
var sm4switch = false;
//var sm4 = new SM4Util();

var base64 =  new Base64();
/**
 *  加密 字符串不包含在对象中
 * @param data
 * @param encrypt 指定加密，不使用全局开关 默认false
 * @returns {*}
 */
export function encryption(data,encrypt=false) {
  if (sm4switch || encrypt) {
    let jsonStr = JSON.stringify(data);
    let base64Encode = base64.encode(jsonStr);
    let encryptJson = sm4.encrypt(base64Encode)
    console.log("加密开始===========================")
    console.log(jsonStr);
    console.log(base64Encode);
    console.log(encryptJson);
    console.log("加密结束===========================")

    //let encryptJson = sm4.encryptData_CBC(base64.encode(JSON.stringify(data)))
    if(typeof data == 'object'){
      const parameter = {
        encryptJson
      }
      return parameter
    }else{
      return encryptJson
    }
  } else {
    return data
  }
}

/**
 *  解密
 * @param data
 * @param decrypt 指定解密，不使用全局开关 默认false
 * @returns {*}
 */
export function decryption(data,decrypt=false) {

  if (sm4switch || decrypt) {
    var prefix = "encryptJson_";
    if(typeof data == 'string'&&(data.startsWith(prefix))){
        console.log("解密开始")
        console.log(data)
      let substring = data.substring(prefix.length,data.length);
      console.log("截取："+substring)
      let content = sm4.decrypt(substring);
      console.log("sm4解密："+content)
      var  text = base64.decode(content);
      console.log("base64解密："+text)
      let response = JSON.parse(text);
      return response
    }else {
      return data
    }

  } else {
    return data
  }
}


/**
 *  加密 字符串不包含在对象中
 * @param data
 * @param encrypt 指定加密，不使用全局开关 默认false
 * @returns {*}
 */
export function encipher(data,encrypt=false) {
  if (sm4switch || encrypt) {
    let encryptJson = sm4.encrypt(JSON.stringify(data))
    return encryptJson
  } else {
    return data
  }
}

/**
 *  解密
 * @param data
 * @param decrypt 指定解密，不使用全局开关 默认false
 * @returns {*}
 */
export function decrypt(data,decrypt=false) {
  if (sm4switch || decrypt) {
    let response = JSON.parse(sm4.decrypt(data));
    return response
  } else {
    return data
  }
}