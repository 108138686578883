const state = {
    isLogin: false,
    routeArr: [],
    fileData: [],
    dictionary: [],
    foregroundRoute: [],
    systemRoute: [],
    backstageRoute: [],
}

// mutations操作数据
const mutations = {

    CHANGESTORE(state, data) {
        state.isLogin = data;
    },

    HEARDER(state, data) {
        state.routeArr = data;
    },
    changeStatus(state, status) {  //  重复赋值
        state.fileData = status;
    },
    changeFore(state, status) {
        state.foregroundRoute = status;
    },
    changeSystem(state, status) {
        state.systemRoute = status;
    },
    changeBack(state, status) {
        state.backstageRoute = status;
    },
}

const actions = {
    updateStoreChange(contenxt, state) {
        contenxt.commit('CHANGESTORE', state)
    },

    Nav({ commit }, state) {
        commit('HEARDER', state)
    },
    editFore({ commit }, state) {
        commit('changeFore', state)
    },
    editSystem({ commit }, state) {
        commit('changeSystem', state)
    },
    editBack({ commit }, state) {
        commit('changeBack', state)
    }
}

// 导出
export default {
    state,
    mutations,
    actions
}