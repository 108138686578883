<template>
  <div>
    <router-view />
  </div>
</template>

<script>
// 加载动态路由
import useaddRoute from "@utils/loadingRouter";
export default {
  components: {
    // Fold,
    // Expand
  },
  data() {
    return {
    };
  },
  watch: {
    $route(oldVal, newVal) {

    }
  },
  created() {
    
  },

  methods: {

  },
  mounted() {
    window.addEventListener("load", () => {
      // useaddRoute();
    });
    // window.addEventListener("beforeunload", () => {
    //   this.$cookies.remove('token');
    //   this.$cookies.remove('userId')
    //   this.$cookies.remove('tenantId')
    //   this.$cookies.remove('system_type')
    // });

  }
};
</script>
<style lang="less">
#app {
  font-family: Song-style;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

.whole_body {
  overflow: hidden;
}

html,
body,
h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
}

li {
  list-style: none
}

.el-form-item__error {
  top: 85% !important;
}
</style>
