const expirationTime = '1800';
const cmsRequestUrl = process.env.VUE_APP_PROJECT_NAME;
const cmsRequestPort = process.env.VUE_APP_API_URL;
const UEDITOR_HOME_URL_PREFIX = process.env.VUE_APP_MODE == 'development' ? '' :  "/dist"
const restrictionFileSize = 2147483648 //文件上传大小 2GB
const editFileType = 'doc,docx,xls,xlsx,xlsm,ppt,pptx'
const viewFileType = 'doc,docx,xls,xlsx,xlsm,ppt,pptx,csv, tsv, dotm, xlt, xltm, dot, dotx,xlam, xla,wps, dps, et, ett, wpt ,odt, ods, ots, odp, otp, six, ott, fodt, fods ,' +
                     ' vsd, vsdx,wmf, emf ,psd ,pdf ,ofd, rtf,xmind,bpmn ,eml ,epub , obj, 3ds, stl, ply, gltf, glb, off, 3dm, fbx, dae, wrl, ' +
                     '3mf, ifc, brep, step, iges, fcstd, bim,dwg, dxf ,zip, rar, jar, tar, gzip, 7z, jpg, jpeg, png, gif, bmp, ico, jfif, webp,' +
                     'tif, tiff ,tga ,svg,mp3,wav,mp4,flv,avi,mov,rm,webm,ts,rm,mkv,mpeg,ogg,mpg,rmvb,wmv,3gp,ts,swf,dcm ,drawio'
const zeroWeb= process.env.VUE_APP_API_URL+"/portal-web"  //zero web服务门户
const flowWebUrl= process.env.VUE_APP_API_URL+"/workflow-web"  //流程服务
const viewFlowDiagram= process.env.VUE_APP_API_URL+"/workflow-web/pages/workFlowPub/viewFlowDiagram_simple.jsp"  //流程服务
const selectProcDef= process.env.VUE_APP_API_URL+"/workflow-web/pages/workFlowPub/selectProcDef.jsp"  //流程选择
const workflowSubmit= process.env.VUE_APP_API_URL+"/workflow-web/pages/workFlowRuntime/process/submit.jsp"  //流程提交
const zipTemplateUrl= "/default/zip/preview.html"  //zip模板地址
export default {
    expirationTime,
    cmsRequestUrl,
    cmsRequestPort,
    UEDITOR_HOME_URL_PREFIX,
    restrictionFileSize,
    editFileType,
    viewFileType,
    flowWebUrl,
    viewFlowDiagram,
    selectProcDef,
    workflowSubmit,
    zeroWeb,
    zipTemplateUrl
}