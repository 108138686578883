import Vue from 'vue'
import App from './App.vue'
import store from './store/index.js'
import router from './router/index.js'
import VueRouter from 'vue-router'
import uploader from 'vue-simple-uploader'
import Contextmenu from "vue-contextmenujs"
// 拖拽
import drawerDrag from "@/utils/directives/drawer-drag";
Vue.directive("drawerDrag", drawerDrag)
import JsonViewer from 'vue-json-viewer';
Vue.use(JsonViewer);

import './utils/ztree/js/jquery.ztree.core.js'
import './utils/ztree/js/jquery.ztree.excheck.js'
import './utils/ztree/css/metroStyle/metroStyle.css'
import './utils/ztree/css/zTreeStyle/zTreeStyle.css';
import './utils/ztree/js/jquery.ztree.exedit.js'

Vue.use(VueRouter);
Vue.use(uploader);
Vue.use(Contextmenu);

// 引入cookies并挂载全局
import VueCookies from 'vue-cookies'
Vue.prototype.$cookies = VueCookies;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import './assets/icon/font/iconfont.css'
import './assets/icon/menu_font/iconfont.css'
import './assets/tree/iconfont.css'
import './assets/icon/myicon/iconfont.css'
//菜单图标
import './assets/icon/menu-icon/iconfont.css'
// 引入font-awesome图标
import 'font-awesome/css/font-awesome.css'
import './assets/style/public.less'
import './assets/style/dialog.less'
import './assets/style/ztree.less'
import './assets/style/formBorder.less'


Vue.use(ElementUI);

ElementUI.Dialog.props.lockScroll.default = false; // 打开弹窗防止页面抖动

//echars 
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;

// 引入消息框提示
import resetMessage from './utils/resetMessage';
Vue.prototype.$message = resetMessage;

// 时间转化格式
import moment from 'moment';
Vue.prototype.$moment = moment;

// websocket
import websocket from './utils/websocket'
Vue.prototype.$websocket = websocket;

// 挂在消息弹窗
import {messagePrompt} from '@utils/message-prompt'
Vue.prototype.$messagePrompt = messagePrompt;

// 引入jQuery并挂载全局
import jquery from 'jquery'
Vue.prototype.$ = jquery;

// 引入全局变量并挂载
import global from '@public/global/global.js'
Vue.prototype.global = global;

// 加解密 挂载全局
import {encryption,encipher,decryption,decrypt} from "@/utils/encryption.js";
Vue.prototype.encryption = encryption;
Vue.prototype.decryption = decryption;
Vue.prototype.encipher = encipher;
Vue.prototype.decrypt = decrypt;

//图标组件
import iconPicker from 'vue-fontawesome-elementui-icon-picker';
Vue.use(iconPicker);
//定义全局函数 全屏
import base from '@utils/fullscreen.js'
Vue.use(base);

Vue.config.productionTip = false;

const whiteList = ['content-list', 'only_office_view', 'preview','/information-add-article']; // 设置白名单
// 挂载路由导航守卫，作用是防止用户跳过登录页面直接从url进入后台管理界面,控制访问权限。
router.beforeEach((to, from, next) => {
    if(to.meta.title){//判断是否有标题
        document.title = to.meta.title
    }
    var token = VueCookies.get('token');
    if (to.path === '/') return next();
    if (!token) {
        if (whiteList.indexOf(to.path) == 0) { // 在免登录白名单，直接进入
            return next()
        } else {
            if (location.href.indexOf('preview') || location.href.indexOf('code') || location.href.indexOf('share')) {
                return next();
            }
            return next('/')
        }
    }
    next()
});

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App),
});