import { staticroute, systemRouter, adminRouter } from "./data";
import router , { resetRouter } from "../router/index";

import store from "../store/index";

export default function useaddRoute() {
    // let staticRouter = [];
    // staticRouter = staticroute;
    // staticRouter[1].children.push(systemRouter[0]);
    // staticRouter[1].children.push(adminRouter[0])
    // // if (sessionStorage.getItem('system_type') == 'application') {
    // //     // 前台路由
    // //     staticRouter = staticroute
    // // }else if (sessionStorage.getItem('system_type') == 'system') {
    // //     // 系统管理路由
    // //     staticRouter.push(systemRouter[0])
    // // }else if (sessionStorage.getItem('system_type') == 'admin') {
    // //     // 后台管理路由
    // //     staticRouter.push(adminRouter[0])
    // // }

    // // if (this.$cookies.get('token')) {

    // // if (store.state.app.isLogin) {
    // // 模拟发送请求 放回后端路由数据 
    // // setTimeout(() => {
    // // 获取动态路由
    // let dynamicRouter = JSON.parse(sessionStorage.getItem('routeArr'));
    // let data = routesData(dynamicRouter);
    // // 将动态路由和静态路由组合一起放到vuex里面保存
    // store.dispatch("editFore", staticroute[1].children[0].children.concat(data));
    // // 系统管理
    // store.dispatch("editSystem", staticroute[1].children[0].children.concat(data));
    // // 后台管理
    // store.dispatch("editBack", adminRouter[0].children.concat(data));

    // let mergeData = staticRouter.concat(data);
    // // 将所有的路由全部放到vuex里面
    // // store.dispatch('Nav', mergeData);
    // resetRouter();
    // // 循环添加  addRoute (vue-router 建议使用的方法)
    // router.addRoutes(mergeData)
}

// 追加动态路由
function routesData(result) {
    if (result && result.length > 0) {
        result.forEach(item => {
            item.component = () => import(`../views/modules/system${item.path}`);
            if (item.children && item.children.length > 0) {
                routesData(item.children)
            }
        })
    } else {
        result = []
    }
    return result
} 
