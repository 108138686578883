//设置全屏
// export function fullscreen(data) {
//       let isfullscreen = !data
//       return isfullscreen
//   }

  exports.install = function (Vue, options) {
    Vue.prototype.fullscreen = function (data){
        let isfullscreen = !data
        // var height = ''
        // if(isfullscreen){
        //     height = $('.el-dialog__body')[0].offsetHeight +'px';
        //     $('.el-dialog__body').css({
        //         height:'calc(100vh - 145px)'
        //     })
        // }else{
        //     $('.el-dialog__body').css({
        //         height:height
        //     })
        // }
        return isfullscreen
    };
  };
  