import Vue from 'vue';

let DOMAIN_URL = (process.env.VUE_APP_MODE == 'development' ? 'ws://127.0.0.1:8899' :  "ws://0.1230123.cn")+'/zero/websocket/';
//连接地址
let url = "";
// 心跳检测时间间隔，单位为毫秒
let heartBeatInterval = 1000 * 60;
let messageCallback = null
// 心跳检测定时器
let heartBeatTimer = null;


// 状态
const state = Vue.observable({
    socket: null,
    connected: false,
    autoReConnect: true,
    reConnectInterval: 3000
});

// 接收ws后端返回的数据
function websocketonmessage (e) {
    messageCallback(JSON.parse(e.data))
}

/**
 *  连接器
 *  id 唯一标识
 * @param autoReConnect 是否自动重连
 * @param reConnectInterval 自动重连时间间隔 单位：毫秒
 */
function connector(id, autoReConnect, reConnectInterval) {
    url = DOMAIN_URL + id ;
    state.autoReConnect = autoReConnect;
    state.reConnectInterval = reConnectInterval;
    //判断当前浏览器是否支持WebSocket
    if ('WebSocket' in window) {
        connect();
    } else {
        console.log('浏览器不支持webSocket');
    }

}
//连接方法
function connect() {
    try {
        state.socket = new WebSocket(url);

        //连接成功
        state.socket.onopen = function () {
            state.connected = true;
            console.log('WebSocket连接成功');
            startHeartBeat();
        };
        //连接出错
        state.socket.onerror = function () {
            //如果设置自动重连
            if (state.autoReConnect) {
                console.log('进入自动重连');
                let time = null; //计时器
                let count = 0;   //重连次数
                //如果没有连接
                if (state.connected === false) {
                    // 延迟3秒重连
                    time = setInterval(function () {
                        count++;
                        console.log('自动重连中第' + count + "次");
                        state.socket = new WebSocket(url);
                        //如果连接成功或者连接次数大于5
                        if (state.connected || count >= 5) {
                            clearInterval(time);
                            console.log('已连接或到达最大重连次数   结束重连');
                        }
                    }, state.reConnectInterval)
                } else {
                    clearInterval(time);
                }
            }
        };
        state.socket.onmessage = function (event) {
            let parse = JSON.parse(event.data);
            if (parse.cmd == 'heartcheck' && parse.msgTxt == '心跳响应') {
                // 接收到消息后重置心跳检测定时器
                resetHeartBeat();
            }else {
                websocketonmessage(event)
            }
            // console.log(parse);
            // console.log('接收到消息：', event.data);

        };
        state.socket.onclose = function () {
            state.socket = null;
            state.connected = false;
            stopHeartBeat();
        }
    } catch (e) {
        console.log(e)
        console.log('连接websocket出现未知错误')
    }
}
//断开连接
function disconnect() {
    if (state.socket != null) {
        state.socket.close();
    }
    state.connected = false
}

/**
 * 发送消息
 * @param message 消息内容
 * @param isConvertToJson 是否需要转换为json串
 */
function send(message, isConvertToJson) {
    if (isConvertToJson) {
        JSON.stringify(message);
        state.socket.send(message)
    } else {
        state.socket.send(message)
    }
}


// 开启心跳检测器
function startHeartBeat() {
    heartBeatTimer = setInterval(function () {
        // 发送心跳检测消息
        state.socket.send('ping');
    }, heartBeatInterval);
}

// 重置心跳检测定时器
function resetHeartBeat() {
    clearInterval(heartBeatTimer);
    startHeartBeat();
}

// 关闭心跳检测器
function stopHeartBeat() {
    clearInterval(heartBeatTimer);
    heartBeatTimer = null;
}

//接收消息 参数：function
function initWebSocket(successCallback){
    connect()
    messageCallback = successCallback
}

export default {
    initWebSocket,
    disconnect,
    connector,
    connect,
    send,
    state
}
