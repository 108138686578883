import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// // 引入静态路由
import { staticroute } from "@/utils/data";
const routes = staticroute

const router = new VueRouter({
    routes
})


export default router