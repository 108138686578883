import { Message } from 'element-ui';
const messagePrompt = (type,msg) => {
    let time = 3000
    if(type == 'error'){
        time = 0
    }
    Message({
        dangerouslyUseHTMLString: true,
        message: `<div>${msg}</div>`,
        type: type,
        duration: time,
        center: true,
        showClose: true
    });
};


  export {
    messagePrompt
}